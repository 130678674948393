<template>
 <div class="sidebar-page">
        <section class="sidebar-layout">
             <b-sidebar
                :position="position"
                :type="type"
                :fullheight="fullheight"
                :left="left"
                :open="showGarden"
                :on-cancel="toggleGarden"
            >
                <form ref="form" class="p-1" id="form">
                    <div class="block">
                        <h6 class="heading">New<span> Garden</span></h6>
                    </div>
                    <!--
                    <div class="field">
                        <label for="" class="label">{{ $t("newGardenMenu.gardenForm.title") }}</label>
                        <div class="control">
                            <input class="input" type="text" placeholder="e.g. Herb Garden" name="title" v-model="gardenTitle">
                        </div>
                        <p class="help is-danger" id="title_type">{{ title_message }}</p>
                    </div>
                    <div class="field">
                        <div class="field">
                            <label for="" class="label">{{ $t("newGardenMenu.gardenForm.length") }}</label>
                            <div class="control">
                                <input class="input" type="number" placeholder="e.g. 5m" name="title" v-model="gardenLength">
                            </div>
                        </div>
                        <div class="field">
                            <label for="" class="label">{{ $t("newGardenMenu.gardenForm.width") }}</label>
                            <div class="control">
                                <input class="input" type="number" placeholder="e.g. 5m" name="title" v-model="gardenWidth">
                            </div>
                        </div>
                    </div>-->        
                    <b-field :label="`${$t('newGardenMenu.gardenForm.title')}`" :type="title_type" :message="title_message">
                        <b-input type="text" :placeholder="`${$t('newGardenMenu.gardenForm.titleExample')}`" name="title" v-model="gardenTitle"></b-input>
                    </b-field>
                    <b-field>
                        <b-field :label="`${$t('newGardenMenu.gardenForm.length')}`"
                            :type="length_type" 
                            :message="length_message"
                            >
                            <b-input type="number" :placeholder="`${$t('newGardenMenu.gardenForm.valueExample')}`" value="" maxlength="30" name="length" v-model="gardenLength"></b-input>
                        </b-field>
                        <div class="p-1"></div>
                        <b-field :label="`${$t('newGardenMenu.gardenForm.width')}`"
                            :type="width_type"
                            :message="width_message" 
                            >
                            <b-input type="number" :placeholder="`${$t('newGardenMenu.gardenForm.valueExample')}`" value="" maxlength="30" name="width" v-model="gardenWidth"></b-input>
                        </b-field>
                    </b-field>
                    <b-field :label="`${$t('newGardenMenu.gardenForm.division')}`" :type="division_type" :message="division_message">
                        <b-input type="number" placeholder="1" value="1" name="division" v-model="gardenDivision"></b-input>
                    </b-field>
                    <div class="center">
                        <b-button type="is-success" rounded outlined native-type="submit">Create</b-button>
                    </div>
                </form>
                <form ref="fertilizerForm" class="p-2" id="fertilizerForm">                   
                    <b-field :label="`${$t('newGardenMenu.fertilizerForm.title')}`" :type="fertilizer_name_type" :message="fertilizer_name_message">
                        <b-input type="text" :placeholder="`${$t('newGardenMenu.fertilizerForm.titleExample')}`" name="title" v-model="fertilizerTitle"></b-input>
                    </b-field>
                    <b-field :type="fertilizer_type_type" :message="fertilizer_type_message">
                        <b-select :placeholder="`${$t('newGardenMenu.fertilizerForm.typeSelection')}`" name="type" v-model="fertilizerType">
                            <option
                                v-for="option in fertilizerOptions"
                                :value="option.name"
                                :key="option.id">
                                {{ option.name }}
                            </option>
                        </b-select>
                    </b-field>
                    <div class="center">
                        <b-button type="is-success" rounded outlined native-type="submit">Create</b-button>
                    </div>
                </form>
            </b-sidebar>
        </section>
    </div>
</template>

<script>
import {mapState, mapMutations} from "vuex";
import {addGarden, addFertilizer} from "../db/dbMethods.js";

export default {
  name: "NewGarden",  
  data() {
    return {
        position: "fixed",
        type: "is-light",
        left: true,
        fullheight: true,

        //properties for the forms
        title_type: "",
        title_message: "",
        length_type: "",
        length_message: "",
        width_type: "",
        width_message: "",
        division_type: "",
        division_message: "",

        fertilizer_name_type: "",
        fertilizer_name_message: "",
        fertilizer_type_type: "",
        fertilizer_type_message: "",

        //data for forms
        gardenTitle: "",
        gardenLength: null,
        gardenWidth: null,
        gardenDivision: 1,

        fertilizerTitle: "",
        fertilizerType: null,

        fertilizerOptions: [
            {
                id: 1,
                name: "Mineral fertilizers",
            },
            {
                id: 3,
                name: "Gaseous fertilizer",
            },
            {
                id: 2,
                name: "Organic fertilizer",
            },
        ],
    };
  },
  computed: {
      ...mapState({
          showGarden: state => state.showGarden
      })
  },
  methods: {
        ...mapMutations({
            toggleGarden: "SHOW_NEW_GARDEN",
        }),
        initForm(event){
            event.preventDefault();

            //data validation
            if(!this.gardenTitle || !this.gardenLength || !this.gardenWidth || !this.gardenDivision){
                //validaiton failed -> show fields that are not valid and show them red
                if(!this.gardenTitle){
                    this.title_type = "is-danger";
                    this.title_message = "Title is required!";
                } else {
                    this.title_type = "is-success";
                    this.title_message = "";
                }
                if(!this.gardenLength){
                    this.length_type = "is-danger";
                    this.length_message = "Length is required!";
                } else {
                    this.length_type = "is-success";
                    this.length_message = "";
                }
                if(!this.gardenWidth){
                    this.width_type = "is-danger";
                    this.width_message = "Width is required!";
                } else {
                    this.width_type = "is-success";
                    this.width_message = "";
                }
                if(!this.gardenDivision){
                    this.division_type = "is-danger";
                    this.division_message = "Square length is required!";
                } else {
                    this.division_type = "is-success";
                    this.division_message = "";
                }
            } else if(form.width.value%form.division.value != 0 || form.length.value%form.division.value != 0) {
                //reseting the other forms
                this.title_message = "";
                this.title_type = "is-success";
                this.length_type = "is-success";
                this.length_message = "";
                this.width_message = "";
                this.width_type = "is-success";
                //division mistake
                this.division_type = "is-danger";
                this.division_message = "Length and width have to be divisable by the square length!";
                
            } else {
                //validation passed
                const garden = {
                    //getting uid from the user object in vuexstore
                    userId: this.$store.state.user.authStatus.uid,
                    division: parseInt(this.gardenDivision),
                    name: this.gardenTitle, 
                    length: parseInt(this.gardenLength), 
                    width: parseInt(this.gardenWidth), 
                    createdSquares: false,
                }
                addGarden(garden);
                //reseting the form
                this.gardenTitle = "";
                this.gardenLength = null;
                this.gardenWidth = null;
                this.gardenDivision = 1;

                
                this.title_type = "";
                this.title_message = "";
                this.length_type = "";
                this.length_message = "";
                this.width_type = "";
                this.width_message = "";
                this.division_type = "";
                this.division_message = "";

                //closing the Menu and reloading the component
                this.toggleGarden();
            }
        },
        initFertilizerForm(event){
            event.preventDefault();
            //validation
            if(!this.fertilizerTitle || !this.fertilizerType){
                //validaiton failed -> show fields that are not valid and show them red
                if(!this.fertilizerTitle){
                    this.fertilizer_name_type = "is-danger";
                    this.fertilizer_name_message = "Name is required!";
                } else {
                    this.fertilizer_name_type = "is-success";
                    this.fertilizer_name_message = "";
                }
                if(!this.fertilizerType){
                    this.fertilizer_type_type = "is-danger";
                    this.fertilizer_type_message = "Type is required!";
                } else {
                    this.fertilizer_type_type = "is-success";
                    this.fertilizer_type_message = "";
                }
            } else {
                //validation passed
                const fertilizer = {
                    //getting uid from the user object in vuexstore
                    userId: this.$store.state.user.authStatus.uid,
                    name: this.fertilizerTitle,
                    type: this.fertilizerType,
                }

                addFertilizer(fertilizer);
                //reseting the form
                this.fertilizerTitle = "";
                this.fertilizerType = null;
                
                this.fertilizer_name_type = "";
                this.fertilizer_name_message = "";
                this.fertilizer_type_type = "";
                this.fertilizer_type_message = "";

                //closing the Menu
                this.toggleGarden();
            }
        }
  },
  mounted(){
        document.getElementById("form").addEventListener("submit", (event) => {
            this.initForm(event);
        });

        document.getElementById("fertilizerForm").addEventListener("submit", (event) => {
            this.initFertilizerForm(event);
        });
  },
};
</script>

<style scoped>
.nav {
    background: #94C973;
}

h6.heading {
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #107869;
    margin-bottom: 32px;
}
h6.heading span {
    font-weight: bold;
}

.p-1 {
    margin: 0 5px;
}

.p-2 {
    margin-top: 55px;
}

.center {
    text-align: center;
    margin-top: 16px;
}

button {
    background: greenyellow;
    border-radius: 10%;
    padding: 10px;
    font-weight: bold;
}
</style>