<template>
  <div>
    <h1 class="heading">{{ $t("dashboard.heading.value") }}</h1>
    <NewGarden />
    <div class="container center">
      <div v-for="garden in gardens" :key="garden.id">
        <GardenCardComp class="garden-card" :title="garden.data.name" :length="garden.data.length" :width="garden.data.width" :division="garden.data.division" :id="garden.id"/>
      </div>
      <div class="fertilizerContainer">
        <div v-for="fertilizer in fertilizers" :key="fertilizer.id">
          <div class="fertilizerCard">
            <FertilizerComp class="fertilizer-comp" :title="fertilizer.data.name" :type="fertilizer.data.type" :id="fertilizer.id"/>
          </div>
        </div>
      </div>
      <b-button type="is-success" rounded size="is-small" @click="toggleGarden" class="button"><i class="fas fa-plus"></i></b-button>

      <!--<b-button type="is-danger" rounded size="is-large" @click="firePushNotification" class="button">Fire Event</b-button>-->
  </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import NewGarden from "./NewGarden";
import GardenCardComp from "./GardenCardComp.vue";
import FertilizerComp from "./FertilizerComp.vue";

import db from "../db/init.js";

export default {
  name: 'HomeComp',
  components: {
    NewGarden,
    GardenCardComp,
    FertilizerComp,
  },
  data() {
    return {
      gardens: [],
      fertilizers: [],
    }
  },
  props: {
    heading: String,
  },
  methods: {
      ...mapMutations({
          toggleGarden: "SHOW_NEW_GARDEN",
          removeGardensnapshot: "DELETE_GARDENSNAPSHOT_BY_ID",
      }),
      /*firePushNotification: function() {
        document.dispatchEvent(
          new CustomEvent("displayNotification")
        )
      }*/
  },
  async created() {
    /*
      USING SNAPSHOTS
    */
    //listen for changes in garden collection
    db.collection("Gardens").where("userId", "==", this.$store.state.user.authStatus.uid).onSnapshot({ includeMetadataChanges: true }, (snapshot) => {
        snapshot.docChanges().forEach(change => {
            if(change.type === "added"){
                const payload = {
                    type: "added",
                    id: change.doc.id,
                    data: change.doc.data()
                }
                this.gardens.push(payload);
            }
            if(change.type === "removed"){
                this.gardens.forEach(garden => {
                  if(garden.id === change.doc.id){
                    this.gardens.splice(this.gardens.indexOf(garden),1);
                  }
                })
            }

            //TODO: interacting if offline or online -> maybe telling user
            let source = snapshot.metadata.fromCache ? "local cache" : "server"; 
            //console.log("Data came from the "+ source);
        })
    },
    //snapshots throw error on logout, because of insufficent permissions 
    err => {
      if(err.message === "Missing or insufficient permissions."){
        console.log("Logout successful!");
      } else {
        console.log(err.message);
      }
    });
    //listening for changes in fertilizer collection
    db.collection("Duenger").where("userId", "==", this.$store.state.user.authStatus.uid).onSnapshot((snapshot) => {
        snapshot.docChanges().forEach(change => {
            if(change.type === "added"){
                //adding new fertilizer to the list
                const payload= {
                  type: "added", 
                  data: change.doc.data(), 
                  id: change.doc.id 
                };
                this.fertilizers.push(payload);
            }
            if(change.type === "removed"){
                this.fertilizers.forEach(fertilizer => {
                  if(fertilizer.id === change.doc.id){
                    this.fertilizers.splice(this.fertilizers.indexOf(fertilizer),1);
                  }
                })
            }

            //let source = snapshot.metadata.fromCache ? "local cache" : "server";
            //console.log("Data came from the "+ source);
        })
    }, 
    //snapshots throw error on logout, because of insufficent permissions 
    err => {
      if(err.message === "Missing or insufficient permissions."){
        //Not showing logout massage twice
      } else {
        console.log(err.message);
      }}
      )
  },
  computed: {
    ...mapState({
      show: state => state.showGarden,
    }),
  }
}
</script>

<style scoped>
.center {
  margin-top: 32px;
  text-align: center;
}
.button{
  margin-top: 24px;
}
h1.heading {
  text-align: center;
  margin-top: 24px;
  font-size: 24px;
  font-weight: bold;
}
.garden-card {
  margin-bottom: 18px;
}

.fertilizerContainer {
  margin-top: 70px;
  display: grid;
  grid-template-columns: 1fr 1fr ;
  position: relative;
}

.fertilizerCard {
  margin-top: 10px;
}

</style>