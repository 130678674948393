<template>
    <section>
        <div class="card gardencard">
            <div @click="openGarden(id)">
              <b-image
                :src="require('@/assets/fields.jpg')"
                alt="Fields"
                :rounded="rounded"
                class="image"
              >
              </b-image>
            </div>
            <div class="garden-details">
                <div class="garden-title" @click="openGarden(id)" @mouseover="changeToPointer" @mouseleave="changeToDefault">{{ title }}</div>
                <div class="garden-info">Dimension: <span class="garden-area">{{ length }}</span> x <span class="garden-area">{{ width }}</span> Meter</div>
                <div class="garden-info">Squarelength: <span class="garden-area">{{ division }}</span></div>
            </div>
            <div class="garden-delete">
                <i class="fas fa-trash" @mouseover="changeToPointer" @mouseleave="changeToDefault" @click="deleteGarden" :data-id="id"></i>
            </div>
        </div>
    </section>
</template>

<script>
import {mapMutations} from "vuex";
import { deleteGarden } from "../db/dbMethods.js";

export default {
    data() {
        return {
            rounded: true
        }
    },
    props: {
      title: String,
      length: Number,
      width: Number,
      division: Number,
      id: String,
    },
    methods: {
      // Entweder so, oder ich leite die id als propertie weiter
      openGarden(id) {
        //Weiterleiten zur Routeransicht
        this.set_gardentitle(this.$props.title);
        this.$router.push(`/garden/${id}`);
      },
      changeToPointer() {
          document.body.style.cursor = "pointer";
      },
      changeToDefault() {
        document.body.style.cursor = "default";
      },
      deleteGarden(e){
        deleteGarden(this.id);
      },
      ...mapMutations({
        set_gardentitle: "SET_GARDENTITLE",
      })
    },
}
</script>

<style scoped>
.image {
    max-width: 128px;
    padding: 10px;
}

.gardencard {
    display: grid;
    border-radius: 8px;
    grid-template-columns: 2fr 6fr 1fr;
    grid-template-areas: "image details delete";
    position: relative;
    margin: 0 80px;
}

.garden img{
  grid-area: image;
  max-width: 60px;
}
.garden-details{
  grid-area: details;
  text-align: left;
  margin-top: 10px;
  margin-right: 72px;
}

.garden-delete{
  grid-area: delete;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.garden-delete i{
  font-size: 36px;
}
.garden-title{
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
}
.garden-info{
  font-size: 0.8em;
  font-size: 16px;
}

.garden-area {
    font-weight: bolder;
}

@media screen and (max-width: 1100px){
  .gardencard {
    margin: 0 20px;
    grid-template-columns: 3fr 5fr 1fr;
  }
  .image {
    margin: 0 35px;
  }
}

@media screen and (max-width: 900px){
  .gardencard {
    margin: 0 20px;
  }
  .image {
    margin: 0;
  }
}

@media screen and (max-width: 600px){
  .gardencard {
    grid-template-columns: 3fr 5fr 1fr;
  }
}

@media screen and (max-width: 468px){
  .gardencard {
    grid-template-columns: 3fr 5fr 1fr;
  }
  .garden-delete i{
    font-size: 24px;
  }
  .garden-details{
    margin-top: 6px;
    margin-right: 10px;
  }
  .garden-title{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  .garden-info{
    font-size: 0.8em;
  }
}

@media screen and (max-width: 420px){
  .image {
    max-width: 92px;
    margin: 5px 0;
  }
  .gardencard {
    grid-template-columns: 3fr 7fr 1fr;
  }
}

</style>