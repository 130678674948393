/*
    All the JS we write to interact with the Database

    The var db stand for the firestore db and comes from the config.js file
*/

import db from "./init.js"
import store from "../store/index.js";

//TODO:enable Persistance for pwa --> where do i have to call this function? 
export function enablePersistence() {
    db.enablePersistence()
    .catch(err => {
        if(err.code == "failed-precondition"){
            //probably multiple tabs open at once
            console.log("Persistence failed");
        } else if(err.code == "unimplemented") {
            //Browser does not support it
            console.log("Persistence is not available");
        }
    });
}

//getting all Gradens
export async function getAllGardens() {
    const gardenDB = await db.collection("Gardens").get();
    let gardens = [];
    gardenDB.docs.forEach(garden => gardens.push({id: garden.id, data: garden.data()}));
    return (gardens);
}

//getting garden by id
export async function getGardenById(id) {
    const gardenDB = await db.collection("Gardens").doc(id).get();
    if(gardenDB){
        return {
            id: gardenDB.id,
            data: gardenDB.data(),
            error: "",
        }
    } else {
        return {
            error: "No garden found with this ID",
            id: "",
            data: {},
        }
    }
}

//adding a Garden to the Database
export async function addGarden(garden) {
    db.collection("Gardens").add(garden)
    .then(() => {
        console.log("Garden sucessfully added!");
    })
    .catch(err => {
        console.log(err);
    })
}

//Deleting a Garden with the id
export function deleteGarden(id){
    db.collection("Gardens")
        .doc(id)
        .delete()
        .then(() => {
            //deleting all squares referenced with this gardenId
            db.collection("Square").where("gardenId", "==", id).get()
                .then(snapshot => {
                    snapshot.forEach(garden => garden.ref.delete())
                })
            console.log("Garden successfully removed!");
        })
        .catch((error) => {
            console.error("Error removing Garden: ", error);
        });
}

//Updating a Garden 
export function updateGardencreatedSquare(newGarden){
    db.collection("Gardens")
        .doc(newGarden.id)
        .update({
            createdSquares: newGarden.data.createdSquares
        })
        .then(() => {
            console.log("Garden successfully updated!");
        })
        .catch(err => {
            console.log("Error updating Garden: ", err);
        })
}

//getting all Fertilizers
export async function getAllFertilizer() {
    const fertilizerDB = await db.collection("Duenger").where("userId", "==", store.state.user.authStatus.uid).get();
    let fertilizers = [];
    fertilizerDB.docs.forEach(fertilizer => fertilizers.push({id: fertilizer.id, data: fertilizer.data()}));
    return fertilizers;
}

//adding a Fertilizer to the Database
export function addFertilizer(fertilizer) {
    db.collection("Duenger").add(fertilizer)
    .then(() => {
        console.log("Fertilizer sucessfully added!");
    })
    .catch(err => {
        console.log("Error adding fertilizer: ", err);
    })
}

//deleting a fertilizer by Id
export function deleteFertilizer(id) {
    db.collection("Duenger")
      .doc(id)
      .delete()
      .then(() => {
          //delteing fertilizer on all squares that require it
        console.log("Fertilizer successfully removed!");
      })
      .catch((error) => {
        console.error("Error removing Fertilizer: ", error);
      });
}

//Creating Squares for graden
export function createSquares(garden){
    console.log(garden.data.width)
    console.log(garden.data.length)
    console.log(garden.data.division)
    try {
        if(garden.data.length % garden.data.division == 0 && garden.data.width % garden.data.division == 0){
            const gardenLength = garden.data.length / garden.data.division;
            const gardenWidth = garden.data.width / garden.data.division;
            const squareAmount = gardenLength * gardenWidth;
            for(let i=1; i<= squareAmount; i++){
                const emptysquare= {
                    fertilizer: "",
                    gardenId: garden.id,
                    specification: "",
                    type: "",
                    number: i,
                };
                db.collection("Square").add(emptysquare)
                .catch(err => {
                    console.log("Error creating squares: ", err);
                })
            }
        } else {
            throw "Length or width of the garden is not divisable by the division!";
        }
    } catch(err) {
        console.log("Error creating squares for Graden: ",err);
    }
}

//Update Square Type
export function updateSquareType(squareId, squareType){
    try{
        db.collection("Square")
            .doc(squareId)
            .update({
                "type": squareType,
                "specification": ""
            })
    } catch(err) {
        console.log("Error saving Square: ", "Something went wrong with the Changing of the type of the field!",err.message);
    }
}

//Update Square Specification
export async function updateSquareSpecification(squareId, squareSpec){
    try{
        db.collection("Square")
            .doc(squareId)
            .update({
                "specification": squareSpec
            })
    } catch(err) {
        console.log("Error saving square specification: ", err);
    }
}

//Update Square fertilizer
export async function updateSquareFertilizer(squareId, squareFertilizer){
    try{
        db.collection("Square")
            .doc(squareId)
            .update({
                "fertilizer": squareFertilizer
            })
    } catch(err) {
        console.log("Error saving square fertilizer: ", err);
    }
}

//get all Squares for Garden
export async function getAllSquaresForGarden(gardenId){
    try {
        let squares = []
        const squaresdb = await db.collection("Square").where("gardenId", "==", gardenId).get();
        squaresdb.docs.forEach(square => squares.push({id: square.id, data: square.data()}));
        return squares;
    } catch(err) {
        console.log("Error getting Squares for Garden: ",err);
    }
}

//get all season endings
export async function getAllSeasonEndings(){
    try{
        const seasonsDB = await db.collection("Seasons").where("userId", "==", store.state.user.authStatus.uid).get();
        let seasons = [];
        seasonsDB.docs.forEach(season => seasons.push({id: season.id, data: season.data()}));
        return seasons;
    } catch(err) {
        console.log("Error getting Season from DB: ",err);
    }
}

//saving a season ending and deleting the garden in the same process
export async function createSeasonEnding(season, id){
    try {
        db.collection("Seasons").add(season)
        .then(() => {
            console.log("Season ending sucessfully added!");
        })
        //deleting the garden
        .then(() => {
            db.collection("Gardens").doc(id).delete()
            .then(() => {
                console.log("Graden successfull deleted!");
            })
        })
    } catch(err) {
        console.log("Error adding season ending: ", err);
    }
}