<template>
  <div>
    <NavComp />
    <div class="container">
      <HomeComp heading="Your Gardens"/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HomeComp from '@/components/HomeComp.vue';
import NavComp from "@/components/NavComp.vue";

export default {
  name: 'Dashboard',
  components: {
    HomeComp,
    NavComp
  },
  metaInfo() {
    return {
      title: "SquareGarden Dashboard",
      meta: [
        {
          name: "description",
          content: "blablabla..."
        }
      ]
    }
  }
}
</script>
