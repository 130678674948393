<template>
    <section>
        <div class="card fertilizercard">
            <div>
              <b-image
                :src="require('@/assets/duenger.jpg')"
                alt="Fertilizer"
                :rounded="rounded"
                class="image"
              >
              </b-image>
            </div>
            <div class="fertilizer-details">
                <div class="fertilizer-title">{{ title }}</div>
                <div class="fertilizer-info">Type: {{ type }}</div>
            </div>
            <div class="fertilizer-delete">
                <i class="fas fa-trash" @mouseover="changeToPointer" @mouseleave="changeToDefault" @click="deleteFertilizer" :data-id="id"></i>
            </div>
        </div>
    </section>
</template>

<script>
import { deleteFertilizer } from "../db/dbMethods.js";

export default {
    name: "FertilizerComp",
    data() {
        return {
            rounded: true
        }
    },
    props: {
        id: String,
        title: String,
        type: String,
    },
    methods: {
        changeToPointer() {
            document.body.style.cursor = "pointer";
        },
        changeToDefault() {
            document.body.style.cursor = "default";
        },
        deleteFertilizer(){
            deleteFertilizer(this.id);
        }
    }
}
</script>

<style scoped>
    .image {
        max-width: 64px;
        padding: 10px;
    }

    .fertilizercard {
        display: grid;
        border-radius: 8px;
        grid-template-columns: 2fr 3fr 1fr;
        grid-template-areas: "image details delete";
        position: relative;
        max-width: 600px;
        margin: 0 80px;
    }

    .fertilizer img{
        grid-area: image;
        max-width: 60px;
    }

    .fertilizer-details{
        grid-area: details;
        text-align: left;
        margin-top: 10px;
        margin-right: 72px;
    }

    .fertilizer-delete{
        grid-area: delete;
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
    .fertilizer-title{
        font-size: 16px;
        font-weight: bolder;
        margin-bottom: 8px;
    }

/* Media Query for mobile support */
@media screen and (max-width: 1216px){
  .fertilizercard {
    margin: 0 20px;
    grid-template-columns: 3fr 5fr 1fr;
  }
  .image {
    margin: 0 35px;
  }
}

@media screen and (max-width: 900px){
  .fertilizercard {
    margin: 0 20px;
  }
  .image {
    margin: 0;
  }
}

@media screen and (max-width: 740px){
    
}

@media screen and (max-width: 600px){
  .fertilizercard {
    grid-template-columns: 3fr 5fr 1fr;
  }
}

@media screen and (max-width: 468px){
  .fertilizercard {
    grid-template-columns: 3fr 5fr 1fr;
  }
  .fertilizer-delete i{
    font-size: 16px;
  }
  .fertilizer-details{
    font-size: 0.8em;
    margin-top: 6px;
    margin-right: 10px;
  }
  .fertilizer-title{
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 420px){
  .image {
    max-width: 92px;
    margin: 5px 0;
  }
  .fertilizercard {
    grid-template-columns: 3fr 7fr 1fr;
  }
}

</style>